import React from 'react';

const Home = () => {
  return (
    <div className="container mx-auto px-4 md:px-6 lg:px-8">
      {/* Title Above the Image */}
      <h1 className="text-4xl font-bold text-center">Welcome to BUILD</h1>
      <h4 className="text-xl text-center mb-4">
        <span className="bg-yellow-300 px-1">A new sort of politics</span>
      </h4>

      {/* Image Section */}
      <div className="relative">
        <div
          className="w-full flex items-center justify-center overflow-hidden relative"
          style={{ paddingBottom: '43.75%', position: 'relative', height: 0 }} // 16:7 aspect ratio (height = width * 7/16)
        >
          <img
            src="/images/ballyk1.png"
            alt="Ballyk Image"
            className="absolute top-0 left-0 w-full h-full object-cover rounded-lg shadow-lg"
          />
        </div>
      </div>

      {/* Title and Blurb Below the Image */}
      <div className="text-left my-8">
        <h1 className="text-4xl font-bold relative inline-block">
          <span className="relative inline-block">
            <span className="relative z-10">What is BUILD?</span>
            <span
              className="absolute left-0 right-0 bottom-0 h-3 bg-yellow-300"
              style={{ bottom: '-0.2rem', zIndex: 0 }}
            ></span>
          </span>
        </h1>
        <p className="text-lg mt-4">
          BUILD is a network of politicians seeking to disrupt Irish politics. Government policies in housing and other areas have seen a whole generation left behind, and we need radical thinking to address the country's most pressing problems.
        </p>
      </div>

      <div className="text-left my-8">
        <h1 className="text-4xl font-bold relative inline-block">
          <span className="relative inline-block">
            <span className="relative z-10">Time to get radical</span>
            <span
              className="absolute left-0 right-0 bottom-0 h-3 bg-yellow-300"
              style={{ bottom: '-0.2rem', zIndex: 0 }}
            ></span>
          </span>
        </h1>
        <p className="text-lg mt-4">
          Ireland's political class is too conservative when it comes to housing. The story of the past decade has been one of gross undersupply of housing relative to population increases, and according to the <a href="https://worksinprogress.co/issue/the-housing-theory-of-everything/" target="_blank" className="underline">The Housing Theory of Everything</a>, housing shortages can lead to <i>"slow growth, climate change, poor health, financial instability, economic inequality, and falling fertility"</i>. With the recently announced 700+ page planning bill, the government is clearly doubling down on the failed policies that got us here. What Ireland needs is major investment in housing and infrastructure, and solutions should run the gamut from legalising over shop living, brownfield developments, street plans, town extensions, transit-oriented development and even new towns. These measures should be accompanied with a declogging of the planning system, and a reduction in spending in non-core areas.
        </p>
      </div>

      {/* Policy Research Header */}
      <h1 className="text-3xl text-center my-4 font-bold">
        <span className="bg-yellow-300 px-1">Policy Research</span>
      </h1>

      {/* Two-Column Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-10">
        {/* Left Column */}
        <div className="space-y-8">

          <div className="card-container">
            <h2 className="card-heading">Value for money</h2>
            <div className="relative">
              <img
                src="/images/poor1.jpg"
                alt="Why Ireland feels poor"
                className="card-image"
                onClick={() => window.open('https://youtu.be/nm5QYUj6DeI', '_blank')}
              />
              <div className="play-icon-overlay">
                <div className="play-icon-background">
                  <svg xmlns="http://www.w3.org/2000/svg" className="play-icon-svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.869v4.262a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                  </svg>
                </div>
              </div>
            </div>
            <p className="card-paragraph">
              In Ireland we pay Scandinavian taxes but do not receive Scandinavian services. Our living standards are diminshed by a government that is growing ever more wasteful, less democratic, and less accountable.
            </p>
          </div>

          <div className="card-container">
            <h2 className="card-heading">Street Plans</h2>
            <div className="relative">
              <img
                src="/images/street-plans.webp"
                alt="Street Plans"
                className="card-image"
                onClick={() => window.open('https://youtu.be/nQX_yzdIEMo', '_blank')}
              />
              <div className="play-icon-overlay">
                <div className="play-icon-background">
                  <svg xmlns="http://www.w3.org/2000/svg" className="play-icon-svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.869v4.262a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                  </svg>
                </div>
              </div>
            </div>
            <p className="card-paragraph">
              We should enable neighbourhoods to democratically densify so as to add housing supply where it's needed most.
            </p>
          </div>
        </div>

        {/* Right Column */}
        <div className="space-y-8">

          <div className="card-container self-start">
            <h2 className="card-heading">Transit Oriented Development</h2>
            <p className="card-paragraph">
              Transit Oriented Development (TOD) maximizes the amount of residential, business, and leisure space within walking distance of public transport. This makes it easier for people to live, work, and play without needing a car, which can help reduce traffic congestion and improve quality of life.
            </p>
            <div className="relative mt-3">
              <img
                src="/images/TOD5.png"
                alt="Transit Oriented Development"
                className="card-image"
                onClick={() => window.open('https://youtu.be/cFS8NKugso4', '_blank')}
              />
              <div className="play-icon-overlay">
                <div className="play-icon-background">
                  <svg xmlns="http://www.w3.org/2000/svg" className="play-icon-svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.869v4.262a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <div className="card-container">
            <h2 className="card-heading">Ireland's broken planning system</h2>
            <div className="relative">
              <img
                src="/images/smithfield1.png"
                alt="Ireland's broken planning system"
                className="card-image"
                onClick={() => window.open('https://www.youtube.com/watch?v=IYmylpzullg', '_blank')}
              />
              <div className="play-icon-overlay">
                <div className="play-icon-background">
                  <svg xmlns="http://www.w3.org/2000/svg" className="play-icon-svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.869v4.262a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                  </svg>
                </div>
              </div>
            </div>
            <p className="card-paragraph">
              As this case in Dublin's Smithfield neighbourhood shows, our planning system needs to be reformed if we are to address our housing challenges.
            </p>
          </div>
        </div>

      </div>

    </div>
  );
};

export default Home;