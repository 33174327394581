import React, { useState } from 'react';
import axios from 'axios';
import { BUILD_API_HOST } from '../config';

const Newsletter = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);

  const handleSubscribe = async () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailPattern.test(email)) {
      try {
        console.log(`Sending subscription request for email: ${email}`);
        const response = await axios.post(`${BUILD_API_HOST}/subscribe`, { email });

        if (response.status === 200) {
          setMessage('Thank you for subscribing!');
          setIsError(false);
          setEmail('');
        } else {
          setMessage('Subscription failed. Please try again later.');
          setIsError(true);
        }
      } catch (error) {
        console.error('Error during subscription:', error.response || error.message || error);
        setMessage('Subscription failed. Please try again later.');
        setIsError(true);
      }
    } else {
      setMessage('Please enter a valid email address.');
      setIsError(true);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubscribe();
    }
  };

  return (
    <div className="newsletter-page">
      <input
        type="email"
        placeholder="Enter your email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        onKeyDown={handleKeyDown}
        className="email-input mt-2 mb-2 p-2 border rounded-lg w-full md:w-1/3"
      />
      <button
        onClick={handleSubscribe}
        className="subscribe-button bg-yellow-300 text-black font-bold px-4 rounded-lg hover:bg-yellow-400 ml-2"
      >
        Subscribe
      </button>
      {message && (
        <p
          className={`message mt-4 ${
            isError ? 'text-red-500' : 'text-green-500'
          }`}
          style={{ color: isError ? 'red' : 'green' }} // Explicit inline styling as a fallback
        >
          {message}
        </p>
      )}
    </div>
  );
};

export default Newsletter;
