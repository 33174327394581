import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const MarkdownPage = ({ filePath }) => {
  const [content, setContent] = useState('');

  useEffect(() => {
    fetch(filePath)
      .then((response) => response.text())
      .then((text) => setContent(text));
  }, [filePath]);

  // Custom component to render links conditionally
  const renderers = {
    a: ({ href, children }) => {
      const isInternal = href && href.startsWith('/');
      return isInternal ? (
        <a href={href}>
          {children}
        </a>
      ) : (
        <a href={href} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      );
    },
  };

  return (
    <div className="markdown-content mx-auto md:w-4/5">
      <ReactMarkdown remarkPlugins={[remarkGfm]} components={renderers}>
        {content}
      </ReactMarkdown>
      <br /><br />
    </div>
  );
};

export default MarkdownPage;