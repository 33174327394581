const candidates = [
  {
    id: 1001,
    name: 'Aideen Lynch',
    constituency: 'Tipperary North',
    blurb: 'Sarah is a passionate advocate for education reform and community development.',
    photo: '/images/candidates/Female4.png',
    party: 'Independent',
    twitter: '',
    linkedin: '',
  },
  {
    id: 1002,
    name: 'Niall Brassil',
    constituency: 'Dublin Bay South',
    blurb: 'Niall has a strong background in healthcare and is committed to improving public health.',
    photo: '/images/candidates/male3.png',
    party: 'Independent',
    twitter: '',
    linkedin: '',
  },
  {
    id: 1003,
    name: 'Marie Cassidy',
    constituency: 'Carlow-Kilkenny',
    blurb: 'Marie is a finance professional interested in the areas of water quality and housing.',
    photo: '/images/candidates/Female1.png',
    party: 'New Democrats',
    twitter: '',
    linkedin: '',
  },
  {
    id: 1004,
    name: 'Séamus Murphy',
    constituency: 'Mayo',
    blurb: 'Seamus is an architect interested in the areas of housing, planning and infrastructure.',
    photo: '/images/candidates/Male1.png',
    party: 'New Democrats',
    twitter: '',
    linkedin: '',
  },
  {
    id: 1005,
    name: 'Máire Lynch',
    constituency: 'Louth',
    blurb: 'Máire is an accountant with an interest in government reform.',
    photo: '/images/candidates/Female2.png',
    party: 'Independent',
    twitter: '',
    linkedin: '',
  },
  {
    id: 1006,
    name: 'Brian Lowe',
    constituency: 'Laois',
    blurb: 'Brian is a businessman whose background is in technology and communications.',
    photo: '/images/candidates/Male2.png',
    party: 'Independent',
    twitter: '',
    linkedin: '',
  },
  {
    id: 1007,
    name: 'Heather McCarthy',
    constituency: 'Clare',
    blurb: 'Heather is a teacher interested in the areas of education and childcare.',
    photo: '/images/candidates/Female3.png',
    party: 'Independent',
    twitter: '',
    linkedin: '',
  },
  {
    id: 1008,
    name: 'Barry Walshe',
    constituency: 'Kildare North',
    blurb: 'Barry is an Irish language teacher interested in the areas of culture and the Irish language.',
    photo: '/images/candidates/male4.png',
    party: 'Independent',
    twitter: '',
    linkedin: '',
  },
  {
    id: 1009,
    name: 'Alice Sullivan',
    constituency: 'Kerry',
    blurb: 'Alice is a special needs assistant, interested in the areas of disability services.',
    photo: '/images/candidates/female5.png',
    party: 'Independent',
    twitter: '',
    linkedin: '',
  },
  // Add more candidates as needed
];

module.exports = { candidates }; // CommonJS export