import React from 'react';

const Contact = () => {
  return (
    <div className="flex justify-center mx-auto p-4">
      <div className="border-yellow-400 border-b-2">
        <h2 className="text-2xl font-bold mb-4">Contact us</h2>
        <p className="mb-6">
          To get in touch, please email{' '}
          <a href="mailto:info@letsbuild.ie" className="text-blue-500 underline">
            info@letsbuild.ie
          </a>
        </p>
      </div>
    </div>
  );
};

export default Contact;