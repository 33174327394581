import React from 'react';
import MarkdownPage from '../components/MarkdownPage';

const Terms = () => {
  return (
    <div>
      <MarkdownPage filePath="/data/terms.md" />
    </div>
  );
};

export default Terms;