// data/positionsPoll.js

export const positionsPoll = [
    {
      question: "Ireland's defence spending should be",
      answers: [
        { label: "Reduced", percentage: 0 },
        { label: "Left as is", percentage: 33 },
        { label: "Moderately increased", percentage: 40 },
        { label: "Greatly increased", percentage: 27 }
      ]
    },
    {
        question: "Ireland should join NATO",
        answers: [
          { label: "Agree", percentage: 24 },
          { label: "Disagree", percentage: 70 },
          { label: "Not sure", percentage: 6 }
        ]
      },
      {
        question: "The 23% VAT rate should be",
        answers: [
          { label: "Increased", percentage: 0 },
          { label: "Reduced", percentage: 80 },
          { label: "Left as is", percentage: 20 },
        ]
      },
    {
      question: "VRT on cars should be",
      answers: [
        { label: "Abolished", percentage: 60 },
        { label: "Reduced", percentage: 30 },
        { label: "Left as is", percentage: 10 },
      ]
    },
    {
        question: "An appropriate Capital Gains Tax rate for Ireland is:",
        answers: [
          { label: "33% (Current level)", percentage: 10 },
          { label: "25%", percentage: 25 },
          { label: "20%", percentage: 45 },
          { label: "1-19%", percentage: 15 },
          { label: "0%", percentage: 5 },
        ]
      },
    {
        question: "Ireland's handling of the Ukrainian refugee crisis has been",
        answers: [
        { label: "Broadly correct", percentage: 55 },
          { label: "Too generous", percentage: 45 },
          { label: "Not generous enough", percentage: 0 },
        ]
      },
      
    {
        question: "Ireland's handling of International Protection Applicants has been",
        answers: [
        { label: "Broadly correct", percentage: 30 },
          { label: "Too generous", percentage: 70 },
          { label: "Not generous enough", percentage: 0 },
        ]
      },
      {
        question: "The legalisation of psilocybin mushrooms for mental health clinics should be",
        answers: [
          { label: "Discouraged", percentage: 23 },
          { label: "Cautiously explored", percentage: 63 },
          { label: "Legalised right away", percentage: 14 },
        ]
      },
      {
        question: "The ban on nuclear research should be",
        answers: [
          { label: "Repealed", percentage: 42 },
          { label: "Retained", percentage: 58 },
        ]
      },
      {
        question: "Public funding for RTÉ should be",
        answers: [
         { label: "Increased", percentage: 0 },
          { label: "Marginally reduced", percentage: 10 },
          { label: "Significantly reduced", percentage: 55 },
          { label: "Discontinued", percentage: 35 },
        ]
      },
      
  ];  