import React from 'react';
import { Link } from 'react-router-dom';
import { candidates } from '../data/candidates'; // Use named import
import { partyLogos } from '../data/partyLogos'; // Use named import

const Candidates = () => {
  return (
    <div className="container mx-auto px-6 sm:px-10 md:px-8 text-center">
      <h1 className="text-4xl font-bold mb-8">Candidates</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {candidates.map((candidate) => (
          <Link
            key={candidate.id}
            to={`/candidate/${candidate.id}`}
            className="candidate-card"
          >
            {/* Party name at the top right and logo centered underneath */}
            {candidate.party && (
              <div className="absolute top-3 right-3 text-right">
                <span className="text-md font-semibold text-gray-800">
                  {candidate.party}
                </span>
                {partyLogos[candidate.party] && (
                  <div className="mt-1">
                    <img
                      src={partyLogos[candidate.party]}
                      alt={`${candidate.party} logo`}
                      className="party-logo"
                    />
                  </div>
                )}
              </div>
            )}

            <div>
              <img
                src={candidate.photo}
                alt={candidate.name}
                className="w-48 h-48 rounded-full mx-auto mb-4"
              />
              <h2 className="text-2xl font-bold mb-2">{candidate.name}</h2>
              <h3 className="text-xl text-gray-600 mb-2">{candidate.constituency}</h3>
              <p className="text-gray-700">{candidate.blurb}</p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Candidates;