import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

const Navbar = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [activePage, setActivePage] = useState('');

  useEffect(() => {
    const path = location.pathname;
    switch (path) {
      case '/':
        setActivePage('Home');
        break;
      case '/about':
        setActivePage('About');
        break;
      case '/whychange':
        setActivePage('Why change');
        break;
      case '/positions':
        setActivePage('Positions');
        break;
      case '/research':
        setActivePage('Research');
        break;
      case '/candidates':
        setActivePage('Candidates');
        break;
      case '/contact': // Add active state for Contact
        setActivePage('Contact');
        break;
      default:
        setActivePage('');
        break;
    }
  }, [location]);

  const handlePageChange = (page) => {
    setActivePage(page);
    setIsOpen(false); // Close the menu on page change (for mobile view)
  };

  return (
    <nav className="bg-white p-4 mt-4 mb-8">
      <div className="container mx-auto flex items-center justify-between">
        <NavLink
          to="/"
          className="relative"
          onClick={() => handlePageChange('Home')}
        >
          <img
            src="/images/logos/build1.jpeg"
            alt="BUILD Logo"
            className="h-14" // Adjust height as needed
          />
        </NavLink>
        <div className="hidden md:flex space-x-8 space-x-4 lg:space-x-12 mx-auto relative items-center">
          {['Home', 'About', 'Why change', 'Positions', 'Candidates'].map((page) => (
            <NavLink
              key={page}
              to={page === 'Home' ? '/' : `/${page.replace(' ', '').toLowerCase()}`}
              className={`nav-item ${activePage === page ? 'text-black' : ''}`}
              onClick={() => handlePageChange(page)}
            >
              {page}
            </NavLink>
          ))}
          <NavLink
            to="/contact"
            className={`nav-item ${activePage === 'Contact' ? 'text-black' : ''}`}
            onClick={() => handlePageChange('Contact')}
          >
            Contact
          </NavLink>
          <NavLink
            to="/donate"
            className="bg-yellow-300 text-black font-bold py-2 px-4 rounded-lg hover:bg-yellow-400"
            onClick={() => handlePageChange('Donate')}
          >
            Donate
          </NavLink>
        </div>
        <div className="md:hidden text-4xl">
          <button onClick={() => setIsOpen(!isOpen)}>
            ☰
          </button>
        </div>
      </div>
      {isOpen && (
        <div className="md:hidden bg-white p-4 flex flex-col items-center">
          {['Home', 'About', 'Why change', 'Positions', 'Candidates', 'Contact'].map((page) => (
            <NavLink
              key={page}
              to={page === 'Home' ? '/' : `/${page.replace(' ', '').toLowerCase()}`}
              className={`nav-item text-black py-2 w-full text-center ${activePage === page ? 'text-yellow-400' : ''}`}
              onClick={() => handlePageChange(page)}
            >
              {page}
            </NavLink>
          ))}
          <NavLink
            to="/donate"
            className="bg-yellow-300 text-black font-bold py-1 px-4 rounded-lg hover:bg-yellow-400 my-2 w-full text-center"
            onClick={() => handlePageChange('Donate')}
          >
            Donate
          </NavLink>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
