import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer'; // Import the Footer component
import Home from './pages/Home';
import About from './pages/About';
import WhyChange from './pages/WhyChange';
import Positions from './pages/Positions';
import Research from './pages/Research';
import Candidates from './pages/Candidates';
import CandidateDetail from './pages/CandidateDetail';
import Donate from './pages/Donate';
import Payment from './pages/Payment';
import Contact from './pages/Contact';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Terms from './pages/Terms';
import Login from './Login';
import pass from './data/password';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const checkStoredPassword = () => {
      const storedPassword = localStorage.getItem('build-password');
      if (storedPassword === pass) {
        setIsLoggedIn(true);
      }
    };

    checkStoredPassword();

    // Set the title of the document to "BUILD"
    document.title = "BUILD";
  }, []);

  const handleLogin = (password) => {
    if (password === pass) {
      setIsLoggedIn(true);
      localStorage.setItem('build-password', password);
    } else {
      alert('Incorrect password');
    }
  };

  return (
    <Router>
      {isLoggedIn ? (
        <div className="flex flex-col min-h-screen">
          <Navbar />
          <div className="container mx-auto mt-4 flex-grow">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/whychange" element={<WhyChange />} />
              <Route path="/positions" element={<Positions />} />
              <Route path="/research" element={<Research />} />
              <Route path="/candidates" element={<Candidates />} />
              <Route path="/candidate/:id" element={<CandidateDetail />} />
              <Route path="/donate" element={<Donate />} />
              <Route path="/payment" element={<Payment />} />
              <Route path="/privacypolicy" element={<PrivacyPolicy />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/contact" element={<Contact />} /> {/* Add route for Contact */}
            </Routes>
          </div>
          <Footer /> {/* Add the Footer component */}
        </div>
      ) : (
        <Login onLogin={handleLogin} />
      )}
    </Router>
  );
}

export default App;