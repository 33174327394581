// config.js

const isLocalHost = (window.location.hostname === 'localhost');
const BUILD_HOST = "http://" + (isLocalHost ? "localhost:3000" : "letsbuild.ie");
const BUILD_API_HOST = isLocalHost ? BUILD_HOST : "https://api.letsbuild.ie";
const STRIPE_KEY = isLocalHost ? 'pk_test_51PiYkhRwXKzXmg1vMh1b5wHKK8ew71bD51hLUDXmNYRy8dKwB1l8xISPTL1lpU4MSVGu2q0pZRkekeRqF5aQFNuk00sHo385R5' : 'pk_live_51PiYkhRwXKzXmg1vhJixfE3ziGq5sFbb60jcgHcUJc2G31O9o6qfW42XZV9phRzksPLUW1zBTHrFg2mjPz0FqX8Y00zTQYHuZW';

module.exports = {
    BUILD_HOST,
    BUILD_API_HOST,
    STRIPE_KEY,
    isLocalHost
  };