import React, { useState, useEffect } from 'react';
import { candidates } from '../data/candidates'; // Ensure correct import path
import { useNavigate, useLocation } from 'react-router-dom';

const donationLimits = {
  individual: {
    anonymous: 599,
    named: 1000,
  },
  corporate: {
    anonymous: 100,
    named: 1000,
  },
};

const Donate = () => {
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [sortedCandidates, setSortedCandidates] = useState([]);
  const [isIndividual, setIsIndividual] = useState(true);
  const [isNamed, setIsNamed] = useState(false);
  const [donationAmount, setDonationAmount] = useState(0);
  const [totalDonationAmount, setTotalDonationAmount] = useState(0);
  const [exceedsMax, setExceedsMax] = useState(false);
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const sorted = [...candidates].sort((a, b) =>
      a.constituency.localeCompare(b.constituency)
    );
    setSortedCandidates(sorted);

    // Check if a candidate ID was passed and pre-select that candidate
    const candidateId = location.state?.candidateId;
    if (candidateId) {
      const selectedCandidate = candidates.find((c) => c.id === candidateId);
      if (selectedCandidate) {
        setSelectedCandidates([{ id: selectedCandidate.id, name: selectedCandidate.name }]);
      }
    }
  }, [location.state]);

  useEffect(() => {
    const maxDonation = isIndividual
      ? isNamed
        ? donationLimits.individual.named
        : donationLimits.individual.anonymous
      : isNamed
        ? donationLimits.corporate.named
        : donationLimits.corporate.anonymous;

    setDonationAmount(maxDonation);
  }, [isIndividual, isNamed]);

  useEffect(() => {
    const totalAmount = Math.round(selectedCandidates.length * donationAmount);
    setTotalDonationAmount(totalAmount);
  }, [selectedCandidates, donationAmount]);

  const handleCheckboxChange = (candidate) => {
    setSelectedCandidates((prev) =>
      prev.some((selectedCandidate) => selectedCandidate.id === candidate.id)
        ? prev.filter((selectedCandidate) => selectedCandidate.id !== candidate.id)
        : [...prev, { id: candidate.id, name: candidate.name }]
    );
  };

  const handleSelectAllChange = (e) => {
    if (e.target.checked) {
      setSelectedCandidates(sortedCandidates.map(candidate => ({ id: candidate.id, name: candidate.name })));
    } else {
      setSelectedCandidates([]);
    }
  };

  const isAllSelected = selectedCandidates.length === sortedCandidates.length;

  const handleProceedToPayment = () => {
    navigate('/payment', {
      state: {
        isNamed,
        isIndividual,
        selectedCandidates,
        donationAmount,
        totalDonationAmount,
      },
    });
  };

  const maxDonation = isIndividual
    ? isNamed
      ? donationLimits.individual.named
      : donationLimits.individual.anonymous
    : isNamed
      ? donationLimits.corporate.named
      : donationLimits.corporate.anonymous;

  return (
    <div className="container mx-auto">
      <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
        <div className="px-4 items-center md:col-span-3 md:justify-self-end">
          <div className="flex justify-center mb-5 ml-12">
            <label className="flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={isAllSelected}
                onChange={handleSelectAllChange}
                className="mr-2 cursor-pointer"
              />
              <span className="font-semibold">Select all</span>
            </label>

          </div>
          <div className="grid grid-cols-2 gap-4 mt-6">
            {sortedCandidates.map((candidate) => (
              <div
                key={candidate.id}
                className={`p-2 md:p-4 rounded-lg shadow-lg flex items-center cursor-pointer ${selectedCandidates.some((selectedCandidate) => selectedCandidate.id === candidate.id) ? 'bg-yellow-100' : 'bg-white'}`} // Conditional class for background color
                onClick={() => handleCheckboxChange(candidate)}
              >
                <img
                  src={candidate.photo}
                  alt={candidate.name}
                  className="w-16 h-16 rounded-full md:mr-4 mr-2"
                />
                <div>
                  <h2 className="md:text-lg text-md font-bold">{candidate.name}</h2>
                  <h3 className="text-md text-gray-600">{candidate.constituency}</h3>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="p-4 justify-self-start md:col-span-2" style={{ maxWidth: '700px' }}>
          <div className="bg-white p-6 rounded-lg mt-0 md:mt-2" style={{ boxShadow: '0 10px 30px rgba(0, 0, 0, 0.2)' }}>
            <div className="mb-4">
              <label className="block mb-2 font-semibold">I am:</label>
              <label className="mr-4">
                <input
                  type="radio"
                  name="individual"
                  value="yes"
                  checked={isIndividual}
                  onChange={() => setIsIndividual(true)}
                  className="mr-2 cursor-pointer"
                />
                An Irish citizen (living in Ireland or abroad)
              </label>
              <br />
              <label>
                <input
                  type="radio"
                  name="individual"
                  value="no"
                  checked={!isIndividual}
                  onChange={() => setIsIndividual(false)}
                  className="mr-2 mt-3 cursor-pointer"
                />
                An Irish registered company
              </label>
            </div>
            <div className="my-5">
              <label className="block mb-2 font-semibold">I want this donation to be:</label>
              <label className="mr-4">
                <input
                  type="radio"
                  name="nameAppear"
                  value="no"
                  checked={!isNamed}
                  onChange={() => setIsNamed(false)}
                  className="mr-2 cursor-pointer"
                />
                Anonymous
              </label>
              <br />
              <label>
                <input
                  type="radio"
                  name="nameAppear"
                  value="yes"
                  checked={isNamed}
                  onChange={() => setIsNamed(true)}
                  className="mr-2 mt-3 cursor-pointer"
                />
                Public (higher limit)
              </label>
            </div>
            <div className="mb-4">
              <label className="block mb-2"><div className="font-semibold">Donation (per candidate)</div>€
                <input
                  value={donationAmount === 0 ? '' : donationAmount}
                  onChange={(e) => {
                    const value = parseInt(e.target.value, 10);
                    if (isNaN(value) || value <= maxDonation) {
                      setDonationAmount(isNaN(value) ? 0 : value);
                      setExceedsMax(false);
                    } else {
                      setExceedsMax(true);
                    }
                  }}
                  className={`border rounded px-2 py-1 no-arrows max-w-20 ${exceedsMax ? 'border-red-500' : ''}`}
                />
                <span className="ml-2 text-gray-600">Max: €{new Intl.NumberFormat('en-IE').format(maxDonation)}</span>
              </label>
              {exceedsMax && <div className="text-red-500">Exceeds maximum donation</div>}
            </div>
            <div className="mb-4">
              <label><div className="font-semibold">Candidates selected</div>{selectedCandidates.length}</label>
            </div>
            <div>
              <label><div className="font-semibold">Total donation:</div>€{new Intl.NumberFormat('en-IE').format(totalDonationAmount)}</label>
            </div>
            <div className="mt-4 flex">
              <button
                className={`px-4 py-2 text-md rounded mt-4 ${totalDonationAmount === 0 ? 'bg-gray-200 cursor-not-allowed' : 'bg-yellow-300 text-black font-bold'}`}
                onClick={handleProceedToPayment}
                disabled={totalDonationAmount === 0}
              >
                Payment
              </button>

              <div className="ml-6 max-w-32 pt-6">
                <img
                  src="https://images.ctfassets.net/6pk8mg3yh2ee/3k43YNxzHGmiG8kegE6UYU/1630624e192927bb81e5d07dd85bd29b/case_study_stripe_4.png"
                  alt="Powered by Stripe"
                />
              </div>
            </div>
            <div className="mt-4 ml-2">
              <button
                onClick={() => setShowMoreInfo(!showMoreInfo)}
                className="text-black underline underline-dashed focus:outline-none"
              >
                Learn more
              </button>
              {showMoreInfo && (
                <div className="mt-2 p-4 border rounded bg-gray-100">
                  <ul className="list-disc ml-6 text-sm">
                    <li>Once the funds have cleared, funds will be passed on to the candidate(s) after deducting a €0.50 + 7.5% + VAT processing fee. This fee covers stripe.com charges, website maintenance and administrative costs associated with processing the donation</li>
                    <li>Donor details will be passed on to the candidate(s)</li>
                    <li>Named (non-anonymous) donations will be listed in the annual report compiled by SIPO (see <a className="text-black underline underline-dashed focus:outline-none" target="_blank" href="https://sipo.ie">sipo.ie</a> for further details)</li>
                    <li>If you prefer to donate directly, you can do so by emailing the candidate(s) and requesting their banking details</li>
                  </ul>
                </div>
              )}
              <ul className="list-disc ml-6 mt-4">
                {selectedCandidates.map((candidate, index) => (
                  <li key={index}>{candidate.name}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Donate;