import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { candidates } from '../data/candidates';

const CandidateDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const candidate = candidates.find((c) => c.id === parseInt(id, 10));

  // Scroll to the top when the component is mounted
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!candidate) {
    return <div>Candidate not found</div>;
  }

  const handleDonateClick = () => {
    navigate('/donate', { state: { candidateId: candidate.id } }); // Pass candidate ID
  };

  return (
    <div className="container mx-auto text-center mt-8">
      <h1 className="text-4xl font-bold mb-8">{candidate.name}</h1>
      <img
        src={candidate.photo}
        alt={candidate.name}
        className="w-48 h-48 rounded-full mx-auto mb-4"
      />
      <h2 className="text-2xl font-bold mb-2">{candidate.constituency}</h2>
      <p className="text-gray-700">{candidate.blurb}</p>

      {/* Button to navigate to the /donate page */}
      <button
        onClick={handleDonateClick}
        className="mt-8 px-6 py-3 bg-yellow-300 text-black font-semibold rounded-lg hover:bg-green-600 focus:outline-none"
      >
        Donate to {candidate.name}
      </button>

      {/* Follow on text and social network logos */}
      {(candidate.twitter || candidate.linkedin) && (
        <div className="mt-8">
          <span className="text-lg font-semibold text-gray-700">Connect on</span>
          <div className="mt-2 flex justify-center space-x-6">
            {candidate.twitter && (
              <a
                href={`https://x.com/${candidate.twitter}`}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center"
              >
                <img
                  src="/images/logos/x.png"
                  alt="X.com logo"
                  className="w-8 h-8 cursor-pointer hover:opacity-80"
                />
              </a>
            )}
            {candidate.linkedin && (
              <a
                href={`https://linkedin.com/in/${candidate.linkedin}`}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center"
              >
                <img
                  src="/images/logos/linkedin.png"
                  alt="LinkedIn logo"
                  className="w-8 h-8 cursor-pointer hover:opacity-80"
                />
              </a>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CandidateDetail;