import React from 'react';
import Newsletter from './Newsletter';

const Footer = () => {
  return (
    <footer className="bg-gray-100 text-gray-700 py-8 mt-12">
      <div className="container mx-auto text-center">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          <div className="px-2">
            <h5 className="text-lg font-bold mb-4">About BUILD</h5>
            <p className="text-sm text-left mb-2">Subscribe to the BUILD newsletter to stay updated about the project.</p>
            <Newsletter />
          </div>
          <div>
            <h5 className="text-lg font-bold mb-4">Quick Links</h5>
            <ul className="text-sm space-y-2">
              <li><a href="/candidates" className="hover:underline">Candidates</a></li>
              <li><a href="/contact" className="hover:underline">Contact</a></li>
              <li><a href="/privacypolicy" className="hover:underline">Privacy policy</a></li>
              <li><a href="/terms" className="hover:underline">Terms & conditions</a></li>
            </ul>
          </div>
          <div>
            <h5 className="text-lg font-bold mb-4">Connect</h5>
            <div className="flex justify-center space-x-8 mt-4">
              {/* Replace with actual links and icons */}
              <a href="https://youtube.com/@polysee" target="_blank" rel="noopener noreferrer">
                <img src="/images/logos/youtube.png" alt="YouTube" className="w-8 h-8 hover:opacity-80 cursor-pointer" />
              </a>
              <a href="https://www.tiktok.com/@buildireland" target="_blank" rel="noopener noreferrer">
                <img src="/images/logos/tiktok.webp" alt="TikTok" className="w-8 h-8 hover:opacity-80 cursor-pointer" />
              </a>
              <a href="https://www.instagram.com/buildireland" target="_blank" rel="noopener noreferrer">
                <img src="/images/logos/instagram.png" alt="Instagram" className="w-8 h-8 hover:opacity-80 cursor-pointer" />
              </a>
              <a href="https://x.com/Build_Ireland" target="_blank" rel="noopener noreferrer">
                <img src="/images/logos/x.png" alt="Twitter (X)" className="w-8 h-8 hover:opacity-80 cursor-pointer" />
              </a>
              <a href="https://www.facebook.com/profile.php?id=61565041163460" target="_blank" rel="noopener noreferrer">
                <img src="/images/logos/facebook.png" alt="Facebook" className="w-8 h-8 hover:opacity-80 cursor-pointer" />
              </a>
            </div>
          </div>
        </div>
        <div className="mt-8 text-sm text-gray-600">
          © 2024 BUILD. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;