import React from 'react';
import MarkdownPage from '../components/MarkdownPage';

const About = () => {
  return (
    <div className="flex justify-center mx-auto p-4 w-full md:w-4/5"> {/* Full width for small screens, 80% for medium and larger */}
      <div className="flex w-full"> {/* Flex container to keep things aligned */}
        <div className="border-l-2 border-yellow-400 mr-4"></div> {/* Vertical line with right margin */}
        <div className="flex-1"> {/* Flex-1 ensures the content takes up remaining width */}
          <MarkdownPage filePath="/data/about.md" />
        </div>
      </div>
    </div>
  );
};

export default About;