import React, { useState, useEffect } from 'react';
import countries from '../data/countries';
import { useLocation } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { CheckCircleIcon } from '@heroicons/react/24/solid'; // Ensure you have Heroicons installed
import { BUILD_API_HOST, STRIPE_KEY } from '../config';

const stripePromise = loadStripe(STRIPE_KEY);

const PaymentForm = ({ selectedCandidates, totalDonationAmount, donationAmount, isNamed, isIndividual }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [name, setName] = useState('');
  const [address1, setAddress1] = useState('');
  const [companyname, setCompanyName] = useState('');
  const [address2, setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [eircode, setEircode] = useState('');
  const [country, setCountry] = useState('IE');
  const [email, setEmail] = useState('');
  const [confirmDonation, setConfirmDonation] = useState(false);
  const [confirmDonation2, setConfirmDonation2] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [paymentSucceeded, setPaymentSucceeded] = useState(false);

  const getCandidateText = () => 'candidate' + (selectedCandidates.length > 1 ? 's' : '');

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);

    // Map selected candidates to their IDs
    const candidateIds = selectedCandidates.map((candidate) => candidate.id);

    // Fetch the client secret from your server and pass the donor's details
    const response = await fetch(BUILD_API_HOST + '/api/create-payment-intent', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        amount: totalDonationAmount * 100,  // Amount in cents
        currency: 'eur',
        candidateIds,  // Pass the candidate IDs to the server
        donor: {
          name,             // Donor's name
          companyname,      // Donor's company name (if applicable)
          address1,         // Address line 1
          address2,         // Address line 2
          city,             // City
          eircode,          // Postal code
          country,          // Country code
          email,             // Donor's email
          isNamed,
          isIndividual
        }
      }),
    });

    const { clientSecret } = await response.json();

    console.log("clientSecret=", clientSecret);
    console.log("STRIPE_KEY=", STRIPE_KEY);

    if (!clientSecret) {
      setErrorMessage('Failed to create payment intent.');
      setIsProcessing(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);

    console.log("cardElement=", cardElement);

    const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name,
          address: {
            line1: address1,
            line2: address2,
            city,
            postal_code: eircode,
            country,
          },
          email,
        },
      },
    });

    if (error) {
      setErrorMessage(error.message);
      setIsProcessing(false);
    } else if (paymentIntent.status === 'succeeded') {
      setPaymentSucceeded(true);
      setIsProcessing(false);
    }
  };


  const isFormValid = () => {
    return (
      name && (isIndividual ? true : companyname) && address1 && city && eircode && country && confirmDonation && confirmDonation2 && stripe && elements
    );
  };

  return (
    <div className="container mx-auto mt-8">
      {paymentSucceeded ? (
        <div className="text-left mx-auto max-w-lg">
          <div className="flex justify-center items-center text-green-500">
            <CheckCircleIcon className="w-16 h-16" />
            <h2 className="text-3xl font-bold ml-4">Success</h2>
          </div>
          <p className="mt-4 ml-8">
            €{new Intl.NumberFormat('en-IE').format(totalDonationAmount)} was successfully paid. You will receive an email confirmation, as will the {getCandidateText()}. Thanks for your support.
          </p>
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="max-w-lg mx-auto p-4 bg-white shadow-md rounded">
          <h2 className="text-2xl font-bold mb-6">Payment Information</h2>

          <div className="mb-4">
            <label className="block text-gray-700">Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="border rounded w-full py-2 px-3 text-gray-700"
              required
            />
          </div>

          {!isIndividual && (
            <div className="mb-4">
              <label className="block text-gray-700">Company Name</label>
              <input
                type="text"
                value={companyname}
                onChange={(e) => setCompanyName(e.target.value)}
                className="border rounded w-full py-2 px-3 text-gray-700"
              />
            </div>
          )}

          <div className="mb-4">
            <label className="block text-gray-700">{isIndividual ? '' : 'Company '}Address 1</label>
            <input
              type="text"
              value={address1}
              onChange={(e) => setAddress1(e.target.value)}
              className="border rounded w-full py-2 px-3 text-gray-700"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700">{isIndividual ? '' : 'Company '}Address 2 (optional)</label>
            <input
              type="text"
              value={address2}
              onChange={(e) => setAddress2(e.target.value)}
              className="border rounded w-full py-2 px-3 text-gray-700"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700">Town / City</label>
            <input
              type="text"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              className="border rounded w-full py-2 px-3 text-gray-700"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700">Eircode / Postcode / Zipcode</label>
            <input
              type="text"
              value={eircode}
              onChange={(e) => setEircode(e.target.value)}
              className="border rounded w-full py-2 px-3 text-gray-700"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700">Country</label>
            <select
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              className="border rounded w-full py-2 px-3 text-gray-700"
              required
            >
              {countries.map((country) => (
                <option key={country.code} value={country.code}>
                  {country.name}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700">Email (for confirmation)</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="border rounded w-full py-2 px-3 text-gray-700"
            />
          </div>

          <div className="mb-6">
            <label className="block text-gray-700">Payment Details</label>
            <CardElement className="p-4 border rounded" />
          </div>

          {errorMessage && <div className="text-red-500 mb-4">{errorMessage}</div>}

          <div className="mb-4">
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={confirmDonation}
                onChange={(e) => setConfirmDonation(e.target.checked)}
                className="mr-2"
              />
              <span className="text-gray-700 ml-4 text-sm">
                {isIndividual ? 'I am an Irish citizen and' : 'On behalf of an Irish-registered company,'} I consent to making{' '}
                {isNamed ? 'a named' : 'an anonymous'}, non-refundable donation to the above {getCandidateText()}
              </span>
            </label>
          </div>
          <div className="mb-4">
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={confirmDonation2}
                onChange={(e) => setConfirmDonation2(e.target.checked)}
                className="mr-2"
              />
              <span className="text-gray-700 ml-4 text-sm">
                {isNamed ? `I acknowledge that details of this donation will be shared with the ${getCandidateText()}, and as a public donation, a declaration to sipo.ie will also be required` : `I acknowledge that details of this donation will be forwarded to the ${getCandidateText()}; but not shared with sipo.ie`}
              </span>
            </label>
          </div>
          <button
            type="submit"
            className={`w-full font-bold py-2 px-4 rounded ${isFormValid() ? 'bg-green-500 text-white' : 'bg-gray-400 text-gray-700 cursor-not-allowed'}`}
            disabled={!isFormValid() || isProcessing}
          >
            {isProcessing ? 'Processing...' : 'Pay €' + new Intl.NumberFormat('en-IE').format(totalDonationAmount)}
          </button>
        </form>
      )}
    </div>
  );
};

const Payment = () => {
  const location = useLocation();
    // Scroll to the top when the component is mounted
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
    
  const { selectedCandidates, totalDonationAmount, donationAmount, isNamed, isIndividual } = location.state || {
    selectedCandidates: [],
    totalDonationAmount: 0,
    donationAmount: 0,
    isNamed: false,
    isIndividual: true
  };

  return (
    <Elements stripe={stripePromise}>
      <div className="container mx-auto mt-8 px-4 sm:px-6 md:px-8">
        <div className="max-w-lg mx-auto p-4 bg-white shadow-md rounded">
          <h2 className="text-2xl font-bold mb-4">Donation Summary</h2>
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
              {selectedCandidates.map((candidate, index) => (
                <div key={index} className="mb-2">
                  {candidate.name}
                </div>
              ))}
              <div className="font-bold">Total</div>
            </div>
            <div className="text-right">
              {selectedCandidates.map((candidate, index) => (
                <div key={index} className="mb-2">
                  €{new Intl.NumberFormat('en-IE').format(donationAmount)}
                </div>
              ))}
              <div className="font-bold">€{new Intl.NumberFormat('en-IE').format(totalDonationAmount)}</div>
            </div>
          </div>
        </div>
        <PaymentForm
          selectedCandidates={selectedCandidates}
          totalDonationAmount={totalDonationAmount}
          donationAmount={donationAmount}
          isNamed={isNamed}  // Pass isNamed to the PaymentForm
          isIndividual={isIndividual}
        />
      </div>
    </Elements>
  );
};

export default Payment;
