import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
const { isLocalHost } = require('../config');

const topics = ['research', 'housing', 'healthcare', 'tax', 'energy', 'justice', 'immigration'];

const SECTIONS_SHOWN = 3;

const Research = () => {
  const [activeSection, setActiveSection] = useState(isLocalHost ? 'healthcare' : 'research');
  const [content, setContent] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    // Fetch Markdown content for the active section
    fetch(`/data/research/${activeSection}.md`)
      .then((res) => res.text())
      .then((text) => setContent(text))
      .catch((err) => console.error(err));
  }, [activeSection]);

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSectionChange = (section) => {
    setActiveSection(section);
    setIsDropdownOpen(false); // Close the dropdown after selecting
  };

  const isExternalLink = (url) => {
    return !url.startsWith('/') && !url.startsWith('#');
  };

  return (
    <div className="md:flex justify-center md:w-4/5 w-full mx-auto px-4">
      {/* Dropdown menu for small screens */}
      <div className="md:hidden mb-4">
        <button
          className="w-full text-lg font-bold text-left bg-gray-100 p-2 rounded flex justify-between items-center"
          onClick={handleDropdownToggle}
        >
          <span className="text-yellow-400">
            {activeSection.charAt(0).toUpperCase() + activeSection.slice(1)}
          </span>
          <span
            className={`transform transition-transform ${isDropdownOpen ? 'rotate-180' : 'rotate-0'}`}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-5 h-5">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
            </svg>
          </span>
        </button>
        {isDropdownOpen && (
          <nav className="flex flex-col space-y-2 mt-2">
            {topics.map((section, index) => (
              <button
                key={section}
                onClick={() => index < SECTIONS_SHOWN && handleSectionChange(section)}
                className={`text-lg text-left hover:underline hover:text-yellow-400 ${activeSection === section ? 'text-yellow-500 border-l-4 border-yellow-400 pl-2' : ''
                  } ${index >= SECTIONS_SHOWN ? 'cursor-not-allowed text-gray-400' : ''}`}
                disabled={index >= SECTIONS_SHOWN}
              >
                {section.charAt(0).toUpperCase() + section.slice(1)}
              </button>
            ))}
          </nav>
        )}
      </div>

      {/* Left menu block for medium and larger screens */}
      <div className="hidden md:block md:w-1/5 pr-4">
        <nav className="flex flex-col space-y-2">
          {topics.map((section, index) => (
            <button
              key={section}
              onClick={() => index < SECTIONS_SHOWN && setActiveSection(section)}
              className={`text-lg text-left hover:underline hover:text-yellow-400 ${activeSection === section ? 'text-yellow-500 border-l-4 border-yellow-400 pl-2' : ''
                } ${index >= SECTIONS_SHOWN ? 'cursor-not-allowed text-gray-400' : ''}`}
              disabled={index >= SECTIONS_SHOWN}
            >
              {section.charAt(0).toUpperCase() + section.slice(1)}
            </button>
          ))}
        </nav>
      </div>

      {/* Right content block */}
      <div className="w-full md:w-4/5 flex flex-col md:flex-row">
        <div className="hidden md:block border-l-2 border-yellow-400 mr-4"></div>
        <div className="markdown-content p-4 flex-1 overflow-hidden">
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            components={{
              h1: ({ node, ...props }) => <h1 className="text-yellow-400" {...props} />,
              h2: ({ node, ...props }) => <h2 className="text-yellow-400" {...props} />,
              a: ({ href, children, ...props }) =>
                isExternalLink(href) ? (
                  <a href={href} target="_blank" rel="noopener noreferrer" {...props}>
                    {children}
                  </a>
                ) : (
                  <a href={href} {...props}>
                    {children}
                  </a>
                ),
            }}
          >
            {content}
          </ReactMarkdown>
        </div>
      </div>
    </div>
  );
};

export default Research;