import React from 'react';
import { positionsPoll } from '../data/positionspoll'; // Assuming positionsPoll is available here

const Positions = () => {
  return (
    <div className="flex justify-center mx-auto p-4 w-full md:w-4/5"> {/* Full width for small screens, 80% for medium and larger */}
      <div className="flex w-full"> {/* Flex container to keep things aligned */}
      <div className="border-l-2 border-yellow-400 mr-6 md:mr-8 md:pl-24"></div> {/* Increased margin on the right */}
        <div className="flex-1 pl-8"> {/* Padding on the left for the content */}
        <h1 className="text-3xl font-bold relative inline-block mb-4">
          <span className="relative inline-block">
            <span className="relative z-10">Core Positions</span>
            <span
              className="absolute left-0 right-0 bottom-0 h-2 bg-yellow-300" /*style={{ bottom: '-0.1rem', zIndex: 0 }}*/
            ></span>
          </span>
        </h1>

          {/* Core Positions */}
          <p className="mb-1">The BUILD network is seeking:</p>
          <ul className="list-disc list-inside mb-8 space-y-1"> {/* Adjust margin between bullets with space-y-1 */}
            <li className="bullet">A planning system that allows the country to grow</li>
            <li className="bullet">Large scale investment in infrastructure: transport, water, energy generation, hospitals, local sports facilities</li>
            <li className="bullet">
              The downsizing of our <a href="https://youtu.be/nm5QYUj6DeI?si=MiKj2g4DW3woe7wv" className="text-blue-500 underline" target="_blank" rel="noopener noreferrer">quango ecosystem</a>
            </li>
            <li className="bullet">
              The restoration of <a href="https://youtu.be/XQnFrJ8S94M?feature=shared" className="text-blue-500 underline" target="_blank" rel="noopener noreferrer">local democracy</a>
            </li>
            <li className="bullet">A reduction of the tax and regulatory burden on individuals and small businesses</li>
            <li className="bullet">A healthcare system based on health savings accounts, co-payments and social health insurance</li>
            <li className="bullet">An innovation-focussed approach to our climate challenges; not the scapegoating of car owners and farmers</li>
            <li className="bullet">Investment in our navy, radar, and cyber security systems</li>
            <li className="bullet">Alignment with EU nations that wish to preserve the principle of subsidiarity and sovereignty</li>
            <li className="bullet">The defunding of divisive advocacy organisations</li>
          </ul>

          {/* Non-Core Positions */}
          <div className="mt-8">
          <h1 className="text-3xl font-bold relative inline-block mb-4">
          <span className="relative inline-block">
            <span className="relative z-10">Non-core positions</span>
            <span
              className="absolute left-0 right-0 bottom-0 h-2 bg-yellow-300"
            ></span>
          </span>
        </h1>
            <p className="text-md mb-4">On other issues, the following candidate poll gives a sense of the diversity of opinion within BUILD:</p>

            <div className="space-y-4 q-and-a mt-4">
              {positionsPoll.map((poll, index) => (
                <div key={index} className="poll-question">
                  <p className="text-md font-semibold">{poll.question}</p>
                  <div className="bullet space-y-2 mb-6">
                    {poll.answers.map((answer, answerIndex) => (
                      <div key={answerIndex} className="flex items-center space-x-2">
                        <span className="text-black w-2/5 sm:w-1/4">{answer.label}</span>
                        <span className="text-black w-12 text-right">{`${answer.percentage}%`}</span>
                        <div className="bg-gray-200 h-6 rounded-lg relative w-20 sm:w-32 md:w-40 lg:w-48">
                          <div
                            className="bg-yellow-400 h-full rounded-lg"
                            style={{ width: `${answer.percentage}%` }}
                          ></div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Positions;